import React, { Component } from "react";

import { ajax } from "../../../network/Ajax";
import Message from "../../../message/Message";
import PredefinedElement from "./PredefinedElement";

/**
 * @param data: Daten des predefined elements
 * @param updatePredefined: Löst ein Update des Elements in der DB aus.
 *        Die Funktion dazu befindet sich in MonitoringApp.js, da die
 *        predefined Werte beim Start der App geladen werden müssen.
 * @param addPredefined: A function to add a new predefined
 * @param removePredefined: A function to remove an existing predefined
 */
class PredefinedEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      predefinedCategories: [
        { predefined_text_category_id: 1, predefined_category: "JEL Code" },
        { predefined_text_category_id: 2, predefined_category: "Title" },
        //{"predefined_text_category_id":3,"predefined_category":"Type of Publication"}, Should not be changed since it is
        { predefined_text_category_id: 4, predefined_category: "Field" },
        { predefined_text_category_id: 6, predefined_category: "Countries" },
      ],
      predefinedCategory: " ",
      newPredefined: "",
      delete: false,
    };
  }

  handleNewPredefinedChange = (event) => {
    this.setState({
      newPredefined: event.target.value,
    });
  };

  handleSelectChange = (event) => {
    this.setState({
      predefinedCategory: event.target.value,
      newPredefined: "",
    });
  };

  save = () => {
    let success = (result) => {
      this.setState({ newPredefined: "" });
      this.props.addPredefined(JSON.parse(result[0].data)[0], null);
    };

    let error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    ajax("POST", "/api/predefined", success, error, {
      predefined_text_category_id: this.state.predefinedCategory,
      predefined_text_label: this.state.newPredefined,
    });
  };

  render() {
    return (
      <div className="container content">
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <h1 className="col-sm-offset-3 col-sm-9">Edit predefined Values</h1>
        </section>

        <div className="row mb-15">
          <div className="col-sm-3 col-sm-offset-3">
            <select
              className="form-control"
              name="predefined_category"
              value={this.state.predefinedCategory}
              onChange={this.handleSelectChange}
            >
              <option value> -- Please select an Option -- </option>
              {this.state.predefinedCategories.map(function (value) {
                return (
                  <option
                    key={value.predefined_text_category_id}
                    value={value.predefined_text_category_id}
                  >
                    {value.predefined_category}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <section className="row">
          <form className="form-horizontal">
            {this.state.predefinedCategory && (
              <div className="form-group">
                <div className="col-sm-8 col-md-offset-3 col-md-6">
                  <input
                    onChange={this.handleNewPredefinedChange}
                    value={this.state.newPredefined}
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="New entry"
                    required={true}
                  />
                </div>
                <div
                  className="col-sm-2 col-md-1 btn btn-primary"
                  onClick={this.save}
                >
                  Save
                </div>
              </div>
            )}

            {this.state.predefinedCategory &&
              this.props.predefined
                .map((element, index) => ({ ...element, absoluteIndex: index }))
                .filter(
                  (elm) =>
                    elm.predefined_text_category_id ==
                    this.state.predefinedCategory
                )
                .map((value) => {
                  return (
                    <PredefinedElement
                      key={value.predefined_text_id}
                      data={value}
                      update={this.props.updatePredefined}
                      remove={this.props.removePredefined}
                    />
                  );
                })}
          </form>
        </section>
      </div>
    );
  }
}

export default PredefinedEditor;
