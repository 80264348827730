import React, { Component } from 'react';

import { ajax } from '../../../network/Ajax';
import { Spinner } from '../../../spinner/Spinner';

class PasswordRecovery extends Component {

    constructor(props) {

        super(props);

        this.state = { email: '', message: '', isLoading: false };

        this.submit = this.submit.bind(this);
        this.change = this.change.bind(this);
    }

    submit(e) {

        e.preventDefault();

        let self = this;

        let success = (response) => {

            self.setState({
                message: "A link has been sent to you via email. Please follow the link to set your password.",
                isLoading: false
            })

        };

        let error = (response) => {

            response.responseText = JSON.parse(response.responseText);

            self.setState({
                message: "We are sorry, there was an error. (" + response.status + " - " + response.responseText.text + ")",
                isLoading: false
            })
        };

        ajax('POST', '/api/account/tokens/password/recovery', success, error, { 'uid' : this.state.email });

        this.setState({ isLoading: true });
    }

    change(e) {

        this.setState({ email: e.target.value });
    }

    render() {

        return <div className="container content">

            { this.state.message ?

                <div className="alert alert-success mt-30"><h4 className="text-center">{ this.state.message }</h4></div>

                :

                <div className="well mt-30">

                    <div className="row">
                        <h4 className="col-sm-offset-4 col-sm-5 mb-30">Request a new Password:</h4>
                    </div>

                    { this.state.isLoading ? <Spinner/> : <form onSubmit={ this.submit } className="form-horizontal">

                        <div className="form-group">
                            <label for="inputEmail" className="col-sm-2 col-sm-offset-2 control-label">Email</label>
                            <div className="col-sm-5">
                                <input value={ this.state.email } onChange={ this.change } name="email" type="email" className="form-control" id="inputEmail" placeholder="Email" />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="col-sm-offset-4 col-sm-5">
                                <input type='submit' value="Request"  className="btn btn-default pull-right" />
                            </div>
                        </div>

                    </form>}

                </div>
            }

        </div>
    }
}

export default PasswordRecovery;