import React, { Component } from "react";

import Jquery from "jquery";
import { ajax } from "../network/Ajax";
import { Spinner } from "../spinner/Spinner";
import JELGeneralNotice from "../notices/JELGeneralNotice";

/**
 * @param data: Daten wenn das Formular zum Update benutzt wird.
 * @param predefined: Die Vordefinierten Werte zur Auswahl in den Dropdowns
 * @param addJelCode: Funktion welche die Jel-Codes in ResearchEditor updated
 * @param add: Function to add a JEL Code
 * @param remove: Function to remove a JEL Code
 * @param isExternal: Flag zur Unterscheidung der admin und user API Endpunkten
 * @param usedIn: Flat ob die Komponente in der publication oder researcher Umgebung genutzt wird
 */
class AddJelCodes extends Component {
  constructor(props) {
    super(props);

    var jelCodesUrl = this.props.isExternal
      ? "/api/publications/jelcode/byresearcher"
      : "/api/publications/jelcode";

    if (this.props.usedIn == "researcher") {
      jelCodesUrl = this.props.isExternal
        ? "/api/profil/jelcode"
        : "/api/researchers/jelcode";
    }

    this.state = {
      jelCodesUrl: jelCodesUrl,
      isLoading: false,
    };
  }

  addJelCode = (event) => {
    var index = event.nativeEvent.target.selectedIndex;
    var jel_code = event.target.value;
    var name = event.nativeEvent.target[index].text;

    var success = (result) => {
      if (result[0].response_code == "0") {
        this.props.add
          ? this.props.add(jel_code, name)
          : this.props.addJelCode(jel_code, name);
      }

      this.setState({
        isLoading: false,
      });
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
      this.setState({
        isLoading: false,
      });
    };

    var exists = false;

    for (var i = 0; i < this.props.data.jel_codes.length; i++) {
      if (
        this.props.data.jel_codes[i] &&
        this.props.data.jel_codes[i].jel_code == jel_code
      ) {
        exists = true;
        break;
      }
    }

    if (!exists) {
      ajax("post", this.state.jelCodesUrl, success, error, {
        publication_id: this.props.data.publication_id,
        jel_code: [jel_code],
        researcher_id: this.props.data.researcher_id,
      });

      this.setState({
        isLoading: true,
      });
    }
  };

  removeJelCode = (index, jel_code) => {
    var success = (result) => {
      if (result[0].response_code == "0") {
        this.props.remove
          ? this.props.remove(jel_code)
          : this.props.addJelCode(null, null, index);
      }

      this.setState({
        isLoading: false,
      });
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);

      this.setState({
        isLoading: false,
      });
    };

    ajax("DELETE", this.state.jelCodesUrl, success, error, {
      publication_id: this.props.data.publication_id,
      jel_code: [jel_code],
      researcher_id: this.props.data.researcher_id,
    });

    this.setState({
      isLoading: true,
    });
  };

  render() {
    var self = this;
    var data = this.props.data;
    var isStoredPublication =
      data.publication_id || data.researcher_id ? true : false;

    return (
      <div className="form-group">
        <label htmlFor="field" className="col-sm-3 control-label">
          {this.props.usedIn == "researcher" ? "Subjects" : "Jel-Codes"}
        </label>
        <div id="field" className="col-sm-9 col-md-6">
          {this.state.isLoading ? <Spinner /> : null}

          {isStoredPublication && data.jel_codes && (
            <ul className="list-unstyled row">
              {this.props.data.jel_codes.map(function (value, index) {
                return value ? (
                  <li className="col-xs-12 mb-7" key={value.jel_code}>
                    <span className="label label-default pull-left">
                      {value.predefined_text_label}
                      {value.jel_code_name}
                    </span>
                    <span
                      className="label label-danger pull-right pointer"
                      onClick={self.removeJelCode.bind(
                        this,
                        index,
                        value.jel_code
                      )}
                    >
                      x
                    </span>
                  </li>
                ) : null;
              })}
            </ul>
          )}

          <select
            id="title"
            className="form-control mb-15"
            name="title"
            onChange={this.addJelCode}
            disabled={!isStoredPublication}
            defaultValue=""
          >
            <option disabled value="">
              {" "}
              --{" "}
              {isStoredPublication
                ? "Add a JEL-Code"
                : "Please save before adding JEL-Codes."}{" "}
              --{" "}
            </option>
            {Jquery.grep(this.props.predefined, function (elm) {
              return elm.predefined_text_category_id == 1;
            })
              .sort(function (a, b) {
                if (a.predefined_text_label < b.predefined_text_label)
                  return -1;
                if (a.predefined_text_label > b.predefined_text_label) return 1;
                return 0;
              })
              .map(function (value, index) {
                return (
                  <option
                    key={value.predefined_text_id}
                    value={value.predefined_text_id}
                  >
                    {value.predefined_text_label}
                  </option>
                );
              })}
          </select>
          {isStoredPublication && <h6>(Changes are saved immediately.)</h6>}

          {data.field == "VWL" ? <JELGeneralNotice /> : null}

          <div className="">
            <hr />
          </div>
        </div>
      </div>
    );
  }
}

export default AddJelCodes;
