import React, { Component } from "react";
import window from "window-or-global";

import { ajax, ajax_get } from "../../../network/Ajax";

import ResearcherListBody from "./ResearcherListBody";
import Filter from "../../../filter/Filter";
import Message from "../../../message/Message";
import Pagination from "../../../filter/Pagination";
import { Spinner } from "../../../spinner/Spinner";

class ResearcherList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      researchers: [],
      showDetails: {},
      filter: [],
      offset: 0,
      limit: 20,
      isLoading: false,
    };

    this.loadResearchers = this.loadResearchers.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.search = this.search.bind(this);
    this.export = this.export.bind(this);
    this.setOffset = this.setOffset.bind(this);
    this.setLimit = this.setLimit.bind(this);
    this.showDetailView = this.showDetailView.bind(this);
  }

  componentDidMount() {
    if (window.location.search) {
      this.loadResearchers();
    }
  }

  loadResearchers() {
    var success = (result) => {
      this.setState({
        response_code: result[0].response_code,
        response_text: result[0].response_text,
        researchers: JSON.parse(result[0].data) || [],
        isLoading: false,
      });
    };

    var error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
        isLoading: false,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    var requestUrl =
      ApiUrl +
      "?na=active&ca==&va=true" +
      (this.state.query
        ? this.state.query
        : "&" + window.location.search.substring(1)) +
      (this.state.offset != 0 ? "&offset=" + this.state.offset : "");

    ajax_get(requestUrl, success, error);

    this.setState({
      isLoading: true,
    });
  }

  exportCSV() {
    var self = this;

    var success = (result) => {
      var downloadUrl =
        ApiUrl + "/export/webview/" + result.token + "?" + self.state.query;

      // TODO: Could this not be done without opening a tab?
      // Or was this done because of the long delays?
      // window.location = downloadURL; would do the trick.
      newTab.location = downloadUrl;
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);
    };

    var newTab = window.open("", "_blank");

    var requestUrl = "/api/account/tokens/download/request";

    ajax("POST", requestUrl, success, error);
  }

  search(query) {
    this.setState(
      {
        query: `&${query}`,
        offset: 0,
      },
      () => this.loadResearchers()
    );
  }

  export(query) {
    this.setState(
      {
        query: `&${query}`,
        offset: 0,
      },
      () => this.exportCSV()
    );
  }

  setOffset(offset) {
    this.setState({ offset }, () => this.loadResearchers());
  }

  setLimit(limit) {
    this.setState({
      limit: limit,
    });
  }

  showDetailView(index) {
    this.setState((state) => ({
      researchers: state.researchers.map((researcher, researcherIndex) => ({
        ...researcher,
        visible:
          researcherIndex == index ? !researcher.visible : researcher.visible,
      })),
    }));
  }

  render() {
    return (
      <div id={Anchor}>
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <Filter
            formOptions={FormOptions}
            search={this.search}
            export={this.export}
            limit={this.state.limit}
            setLimit={this.setLimit}
            name="Researcher"
          />
        </section>
        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section className="row">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Affiliations</th>
                    <th>Email</th>
                    <th>Field</th>
                    <th>Ranking</th>
                    <th>Verified</th>
                    <th>ID</th>
                    <th></th>
                  </tr>
                </thead>
                <ResearcherListBody
                  data={this.state.researchers}
                  getOffset={this.state.offset}
                />
              </table>
            </div>
          </section>
        )}
        <Pagination
          data={this.state.researchers}
          limit={this.state.limit}
          getOffset={this.state.offset}
          setOffset={this.setOffset}
          anchor={Anchor}
        />
      </div>
    );
  }
}

const ApiUrl = "/api/researchers";
const Anchor = "researcher-list";
const FormOptions = [
  {
    key: "lastname",
    val: "Last name",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "firstname",
    val: "First name",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "title_name",
    val: "Title",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "affiliation_names",
    val: "Affiliations",
    comp: [["@>", "search"]],
    type: "text",
  },
  {
    key: "comments_internal",
    val: "Internal comment",
    comp: [["~*", "search"]],
    type: "text",
  },
  {
    key: "email",
    val: "Email",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "gender",
    val: "Gender",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["F", "female"],
      ["M", "male"],
    ],
  },
  {
    key: "field",
    val: "Field",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["BWL", "BWL"],
      ["VWL", "VWL"],
    ],
  },
  {
    key: "ranking_relevant",
    val: "Relevant for ranking",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "robinson",
    val: "Robinson",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "verified",
    val: "Verified",
    comp: [
      ["=", "="],
      ["!=", "!="],
    ],
    opt: [
      ["true", "Yes"],
      ["false", "No"],
    ],
  },
  {
    key: "created",
    val: "Created",
    comp: [
      ["=", "at"],
      ["<", "before"],
      [">", "after"],
    ],
    type: "date",
  },
  {
    key: "researcher_id",
    val: "ID",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["@>", "contains"],
    ],
    type: "text",
  },
];

export default ResearcherList;
