import React, { Component } from "react";

import { ajax } from "../../../network/Ajax";

/**
 * @param weight: Gewichts Daten für dieses Element
 * @param updateWeights: Update an existing weight for a given year
 */
class WeightElement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleSave: false,
    };
  }

  handleInputChange = (event) => {
    console.log("changing", event.target.name, event.target.value);
    this.props.updateWeights(this.props.weight.year, {
      ...this.props.weight,
      [event.target.name]: event.target.value,
    });

    this.setState({
      visibleSave: true,
    });
  };

  save = () => {
    let success = (result) => {
      this.props.updateWeights(
        this.props.weight.year,
        JSON.parse(result[0].data)[0]
      );
      this.setState({
        visibleSave: false,
      });
    };

    ajax("POST", "/api/weight", success, null, this.props.weight);
  };

  remove = () => {
    let success = () => {
      this.props.updateWeights(this.props.weight.year, {
        ...this.props.weight,
        vwl_weight: "",
        bwl_weight: "",
      });
      this.setState({
        visibleSave: false,
      });
    };

    ajax("DELETE", "/api/weight", success, null, this.props.weight);
  };

  render() {
    return (
      <section>
        <h4 className="kof-color">{this.props.weight.year}</h4>
        <div className="form-inline">
          <div className="form-group">
            <label htmlFor="bwl">BWL</label>
            <input
              onChange={this.handleInputChange}
              value={this.props.weight.bwl_weight}
              className="form-control ml-15"
              id="bwl"
              name="bwl_weight"
              placeholder="BWL"
              required={true}
              type="number"
            />
          </div>
          <div className="form-group">
            <label htmlFor="vwl">VWL</label>
            <input
              onChange={this.handleInputChange}
              value={this.props.weight.vwl_weight}
              className="form-control ml-15"
              id="vwl"
              name="vwl_weight"
              placeholder="VWL"
              required={true}
              type="number"
            />
          </div>
          <div className="pull-right">
            {this.state.visibleSave && (
              <div className="btn btn-primary" onClick={this.save}>
                Save
              </div>
            )}
            <div className=" ml-15 btn btn-danger" onClick={this.remove}>
              Delete
            </div>
          </div>

          <hr />
        </div>
      </section>
    );
  }
}

export default WeightElement;
