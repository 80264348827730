import React, { Component } from "react";
import window from "window-or-global";

import { ajax_get } from "../../../network/Ajax";
import Filter from "../../../filter/Filter";
import Message from "../../../message/Message";
import Pagination from "../../../filter/Pagination";
import { Spinner } from "../../../spinner/Spinner";
import PublicationListBody from "./../publication/PublicationListBody";

class ProfilePublicationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response_code: "0",
      data: [],
      showDetails: {},
      filter: [],
      offset: 0,
      limit: 20,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    let success = (result) => {
      this.setState({
        response_code: result[0].response_code,
        response_text: result[0].response_text,
        data: JSON.parse(result[0].data) || [],
        isLoading: false,
      });
    };

    let error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
        isLoading: false,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    var requestUrl =
      ApiUrl +
      "?na=active&ca==&va=true" +
      (this.state.query
        ? this.state.query
        : "&" + window.location.search.substring(1)) +
      (this.state.offset != 0 ? "&offset=" + this.state.offset : "") +
      "&orderby=-publication_year";

    ajax_get(requestUrl, success, error);

    this.setState({
      data: [],
      isLoading: true,
    });
  }

  search(query) {
    this.setState(
      {
        query: `&${query}`,
        offset: 0,
      },
      () => this.loadData()
    );
  }

  setOffset(offset) {
    this.setState(
      {
        offset,
      },
      () => this.loadData()
    );
  }

  setLimit(limit) {
    this.setState({
      limit: limit,
    });
  }

  sortData(key) {
    function sortPublication(a, b) {
      if (
        Object.prototype.toString.call(a[key]) === "[object Array]" &&
        Object.prototype.toString.call(b[key]) === "[object Array]"
      ) {
        var textA = a[key].sort().toString().toUpperCase();
        var textB = b[key].sort().toString().toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      } else if (typeof a[key] === "string" && typeof b[key] === "string") {
        var textA = a[key].toUpperCase();
        var textB = b[key].toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      } else {
        return b[key] - a[key];
      }
    }
    this.setState((state) => ({
      data: [...state.data].sort(sortPublication),
    }));
  }

  render() {
    //necessary to have the outer 'this-context' at hand within map()
    var self = this;

    return (
      <div id={Anchor}>
        {this.state.response_code == "0" ? null : (
          <Message
            message={this.state.response_text}
            code={this.state.response_code}
          />
        )}
        <section className="row">
          <Filter
            formOptions={FormOptions}
            search={this.search.bind(this)}
            limit={this.state.limit}
            setLimit={this.setLimit}
            name="Publications"
            isProfile={true}
          />
        </section>
        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <section className="row">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(this, "title")}
                    >
                      Title&#x25BC;
                    </th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(this, "publication_id")}
                    >
                      ID&#x25BC;
                    </th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(this, "journal_title")}
                    >
                      Journal (ID)&#x25BC;
                    </th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(this, "publication_year")}
                    >
                      Year&#x25BC;
                    </th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(this, "author_names")}
                    >
                      Authors&#x25BC;
                    </th>
                    <th
                      className="pointer"
                      onClick={self.sortData.bind(this, "publication_type")}
                    >
                      Type&#x25BC;
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <PublicationListBody
                  data={self.state.data}
                  getOffset={this.state.offset}
                  isExternal={true}
                />
              </table>
            </div>
          </section>
        )}
        <Pagination
          data={this.state.data}
          limit={this.state.limit}
          getOffset={this.state.offset}
          setOffset={this.setOffset.bind(this)}
          anchor={Anchor}
        />
      </div>
    );
  }
}

const ApiUrl = "/api/publications/byresearcher";
const Anchor = "profile-publication-list";
const FormOptions = [
  {
    key: "title",
    val: "Title",
    comp: [
      ["~*", "search"],
      ["=", "="],
      ["!=", "!="],
    ],
    type: "text",
  },
  {
    key: "publication_year",
    val: "Year of Publication",
    comp: [
      ["=", "="],
      ["!=", "!="],
      ["<", "<"],
      [">", ">"],
    ],
    type: "text",
  },
  {
    key: "author_names",
    val: "Author Name",
    comp: [
      ["@>", "contains"],
      ["=", "="],
    ],
    type: "text",
  },
];

export default ProfilePublicationList;
