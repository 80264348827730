import React, { Component } from "react";
import Jquery from "jquery";
import Moment from "moment";

import { ajax } from "../network/Ajax";
import LocationSearch from "../search/LocationSearch";
import { Spinner } from "../spinner/Spinner";
import Message from "../message/Message";

function sanitizedAffiliation(affiliation = {}) {
  return {
    index: null,
    affiliation_id: affiliation.affiliation_id || "",
    organization_id: affiliation.organization_id || "",
    organization_name: affiliation.organization_name || "",
    employment: affiliation.employment || "",
    from_date: affiliation.from_date
      ? Moment(affiliation.from_date).format("Y-MM-DD")
      : "",
    to_date:
      affiliation.to_date == "Today"
        ? ""
        : affiliation.to_date
        ? Moment(affiliation.to_date).format("Y-MM-DD")
        : "",
    title: affiliation.title || "",
    title_name: affiliation.title_name || "",
    description: affiliation.description || "",
  };
}
/**
 * @param data: Daten wenn das Formular zum Update benutzt wird.
 * @param add: Function to add an affiliation
 * @param remove: Function to remove an affiliation
 * @param isExternal: Flage welche zeigt ob die Komponente für interne Admins oder externe Accounts genutzt wird
 * @param predefined: Die Vordefinierten Werte zur Auswahl in den Dropdowns
 */

class AddAffiliation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      temp: {},
      response_code: "0",
      affiliation: sanitizedAffiliation(),
      affiliationUrl: this.props.isExternal
        ? "/api/profil/affiliation"
        : "/api/researchers/affiliation",
      isLoading: false,
    };
  }

  saveAffiliation = () => {
    var success = (result) => {
      if (result[0].response_code == "0") {
        const affiliation_id = JSON.parse(result[0].data)[0].affiliation_id;
        const isNew = !this.state.affiliation.affiliation_id;
        this.setState(
          (state) => ({
            affiliation: { ...state.affiliation, affiliation_id },
          }),
          () =>
            isNew
              ? this.props.add(this.state.affiliation)
              : this.props.update(this.state.affiliation)
        );
        this.clearForm();
      } else {
        this.setState({
          response_code: result[0].response_code,
          response_text: result[0].response_text,
        });
      }

      this.setState({
        isLoading: false,
      });
    };

    var error = (xhr, status, err) => {
      var response = JSON.parse(xhr.responseText)[0];

      this.setState({
        response_code: response.response_code,
        response_text: response.response_text,
        isLoading: false,
      });

      console.error(status, err.toString(), xhr.toString);
    };

    var a = { ...this.state.affiliation };

    a.researcher_id = this.props.data.researcher_id;

    if (!Moment(a.from_date, "Y-MM-DD").isValid()) {
      a.from_date = null;
    }

    if (!Moment(a.to_date, "Y-MM-DD").isValid()) {
      a.to_date = null;
    }

    var httpVerb = a.affiliation_id ? "PUT" : "POST";

    ajax(httpVerb, this.state.affiliationUrl, success, error, a);

    this.setState({
      isLoading: true,
    });
  };

  removeAffiliation = (index, affiliation_id) => {
    var success = (result) => {
      if (result[0].response_code == "0") {
        this.props.remove(affiliation_id);
      }

      this.setState({
        isLoading: false,
      });
    };

    var error = (xhr, status, err) => {
      console.error(status, err.toString(), xhr.toString);

      this.setState({
        isLoading: false,
      });
    };

    ajax("DELETE", this.state.affiliationUrl, success, error, {
      affiliation_id: affiliation_id,
    });

    this.setState({
      isLoading: true,
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const updatedValue =
      target.type === "checkbox" ? target.checked : target.value;
    this.setState((state) => ({
      affiliation: {
        ...state.affiliation,
        [target.name]: updatedValue,
      },
    }));

    if (target.name == "title") {
      var index = event.nativeEvent.target.selectedIndex;
      const title_name = event.nativeEvent.target[index].text;
      this.setState((state) => ({
        affiliation: {
          ...state.affiliation,
          title_name,
        },
      }));
    }
  };

  onLocationSelect = (_fieldName, organization_id, organization_name) => {
    this.setState((state) => ({
      affiliation: {
        ...state.affiliation,
        organization_id,
        organization_name,
      },
    }));
  };

  editAffiliation = (index, affiliation) => {
    this.setState((state) => ({
      affiliation: {
        ...state.affiliation,
        ...sanitizedAffiliation(affiliation),
        index,
      },
    }));

    Jquery("html, body").animate(
      {
        scrollTop: Jquery("#affiliation-editor").offset().top,
      },
      500
    );
  };

  clearForm = () => {
    this.setState({
      affiliation: sanitizedAffiliation(),
    });
  };

  render() {
    var self = this;
    var data = this.props.data;
    var isStoredResearcher = data.researcher_id ? true : false;
    var domain = "forschungsmonitoring.org";

    return (
      <div className="form-group">
        <label htmlFor="field" className="col-sm-3 control-label">
          Affiliations
        </label>

        {this.state.isLoading ? (
          <div className="col-sm-9 col-md-6">
            <Spinner />{" "}
          </div>
        ) : null}

        {isStoredResearcher ? (
          <div className="col-sm-9 col-md-6">
            {isStoredResearcher && data.affiliations && (
              <ul className="list-unstyled row">
                {data.affiliations
                  .sort(function (a, b) {
                    if (a && b) {
                      //to_date's can also contain the string 'Today'
                      var startA = a.to_date;
                      var startB = b.to_date;

                      if (startA == "Today") {
                        startA = new Date();
                      }
                      if (startB == "Today") {
                        startB = new Date();
                      }

                      return new Date(startB) - new Date(startA);
                    }
                  })
                  .map(function (value, index) {
                    return value ? (
                      <div className="col-xs-12" key={value.affiliation_id}>
                        <li
                          className={
                            "panel " +
                            (value.to_date == "Today" ||
                            (value.to_date &&
                              Moment(value.to_date).isSameOrAfter(
                                new Date(),
                                "day"
                              ))
                              ? "panel-primary"
                              : "panel-default")
                          }
                        >
                          <div className="panel-heading clearfix">
                            <h4 className="pull-left">
                              {value.organization_name}
                            </h4>
                            <span
                              className="label label-danger pull-right pointer"
                              onClick={self.removeAffiliation.bind(
                                this,
                                index,
                                value.affiliation_id
                              )}
                            >
                              x
                            </span>
                          </div>

                          <div className="panel-body">
                            {value.from_date && (
                              <span>
                                {" "}
                                {Moment(value.from_date).format("Do MMMM YYYY")}
                              </span>
                            )}
                            {value.to_date && value.to_date != "Today" && (
                              <span>
                                {" "}
                                - {Moment(value.to_date).format(
                                  "Do MMMM YYYY"
                                )}{" "}
                              </span>
                            )}
                            {value.employment && (
                              <span> | {value.employment} %</span>
                            )}
                            <br />
                            {value.title_name && (
                              <span>{value.title_name}</span>
                            )}
                            {value.description && (
                              <span> | {value.description}</span>
                            )}
                            <div
                              onClick={self.editAffiliation.bind(
                                null,
                                index,
                                value
                              )}
                              className="btn btn-primary pull-right"
                            >
                              Edit
                            </div>
                          </div>
                        </li>
                      </div>
                    ) : null;
                  })}
              </ul>
            )}

            <div id="affiliation-editor">
              {this.state.response_code == "0" ? null : (
                <Message
                  message={this.state.response_text}
                  code={this.state.response_code}
                />
              )}

              {isStoredResearcher && (
                <div className="mt-30">
                  <h4>Add your current and past affiliations</h4>
                  <h6>
                    (Changes to the affiliations are stored immediately, no need
                    to click save.)
                  </h6>
                </div>
              )}

              <LocationSearch
                onSelect={this.onLocationSelect}
                fieldName=""
                value={[
                  this.state.affiliation.organization_id,
                  this.state.affiliation.organization_name,
                ]}
                disabled={this.state.affiliation.affiliation_id ? true : false}
              />

              <div className="col-xs-3 mb-7 no-pr">
                <input
                  value={this.state.affiliation.employment}
                  onChange={this.handleInputChange}
                  className="form-control"
                  name="employment"
                  type="number"
                  placeholder="1-100% employment"
                />
              </div>

              {this.state.affiliation.affiliation_id && (
                <h6 className="col-xs-9 mt-0 no-pl red">
                  If you need to change the location, please delete the
                  affiliation and add a new one.
                </h6>
              )}

              <div className="col-xs-9 no-pl mb-7">
                <select
                  id="title"
                  className="form-control"
                  name="title"
                  value={this.state.affiliation.title}
                  onChange={this.handleInputChange}
                >
                  <option disabled selected value="">
                    {" "}
                    -- Title --{" "}
                  </option>
                  {this.props.predefined
                    .filter((elm) => elm.predefined_text_category_id == 2)
                    .map(function (value) {
                      return (
                        <option
                          key={value.predefined_text_id}
                          value={value.predefined_text_id}
                        >
                          {value.predefined_text_label}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-xs-9 no-pl mb-7">
                <select
                  id="description"
                  className="form-control"
                  name="description"
                  value={this.state.affiliation.description}
                  onChange={this.handleInputChange}
                >
                  <option disabled value="">
                    {" "}
                    -- Position --{" "}
                  </option>
                  <option>PhD Student</option>
                  <option>Other (Postdoc/ Researcher / …)</option>
                  <option>Full Professor</option>
                </select>
              </div>

              <div className="col-xs-4 no-pl">
                <input
                  value={this.state.affiliation.from_date}
                  onChange={this.handleInputChange}
                  type="text"
                  className="form-control"
                  id="from-date"
                  name="from_date"
                  placeholder="Start: YYYY-MM-DD"
                />
              </div>
              <div className="col-xs-4">
                <input
                  value={this.state.affiliation.to_date}
                  onChange={this.handleInputChange}
                  type="text"
                  className="form-control"
                  id="to_date"
                  name="to_date"
                  placeholder="End: YYYY-MM-DD"
                />
              </div>

              {!this.state.affiliation.affiliation_id ? (
                <div
                  className="col-xs-2 btn btn-primary pull-right"
                  onClick={this.saveAffiliation}
                >
                  Add
                </div>
              ) : (
                <div className="col-xs-4 no-pr">
                  <div
                    className="btn btn-primary pull-right ml-15"
                    onClick={this.saveAffiliation}
                  >
                    Update
                  </div>
                  <div
                    className="btn btn-default pull-right"
                    onClick={this.clearForm}
                  >
                    Cancel
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="col-sm-9 col-md-6">
            <input
              className="form-control"
              disabled={true}
              placeholder=" -- Please save your Profile before your add affiliations -- "
            ></input>
          </div>
        )}
        <div className="col-sm-offset-3 col-sm-9 col-md-6">
          <a
            className="show pt-15 grey"
            href={
              "mailto:kontakt" +
              "@" +
              domain +
              "?subject=Organization%20Request"
            }
          >
            Are you missing an Organization? Ask us via Email.
          </a>
          <hr />
        </div>
      </div>
    );
  }
}

export default AddAffiliation;
